<template>
  <form id="invoiceForm">
    <money-type
      v-model="value['amount']"
      v-validate="{ rules: { required: true }}"
      :clear="clear"
      :error-message="vErrors.first('amount')"
      :required="true"
      label="Kwota netto"
      name="amount"
      data-vv-value-path="value"
      data-vv-as="kwota"
    />
    <div class="row">
      <input-type
        v-model="value['invoiceNumber']"
        v-validate="`${value['date'] ? 'required' : ''}`"
        class="col-xs-6"
        :error-message="vErrors.first('invoiceNumber')"
        label="Numer faktury"
        name="invoiceNumber"
        data-vv-value-path="value"
        data-vv-as="numer faktury"
      />
      <date-picker-type
        v-model="value['date']"
        v-validate="`${value['invoiceNumber'] ? 'required' : ''}`"
        class="col-xs-6 fv-datepicker"
        :error-message="vErrors.first('date')"
        label="Data"
        name="date"
        data-vv-value-path="value"
        data-vv-as="data"
      />
    </div>
    <text-area-type
      v-model="value['description']"
      v-validate="{rules: {max: 512}}"
      label="Opis"
      name="description"
      placeholder="Wpisz treść... Opis nie może przekroczyć 512 znaków."
      :error-message="vErrors.first('description')"
      :required="false"
      data-vv-value-path="value"
      data-vv-as="opis"
    />
    <div
      v-if="taskType === 'main'"
      class="row"
    >
      <div class="col-xs-2 correction-checkbox-first-container">
        <label for="correction-checkbox" class="correction-checkbox-first-label">Korekta</label>
        <input
          id="correction-checkbox"
          v-model="value['correction']"
          type="checkbox"
          @change="(event) => handleCorrectionChange(event)"
        >
        <label for="correction-checkbox"></label>
      </div>
      <input-type
        v-if="value['correction']"
        v-model="value['originalInvoiceNumber']"
        v-validate="`${value['correction'] ? 'required' : ''}`"
        class="col-xs-10"
        :error-message="vErrors.first('correctionInvoiceNumber')"
        label="Oryginalny numer faktury"
        name="correctionInvoiceNumber"
        data-vv-value-path="value"
        data-vv-as="oryginalny numer faktury"
      />
    </div>
  </form>
</template>

<script>
import InputType from '../../../../share/form/type/InputType'
import DatePickerType from '../../../../share/form/type/DatePickerType'
import FormModalValidation from '../../../../share/form/FormModalValidation'
import MoneyType from '../../../../share/form/type/MoneyType'
import TextAreaType from '../../../../share/form/type/TextAreaType'
import {Validator} from 'vee-validate'

export default {
  components: {
    InputType,
    DatePickerType,
    MoneyType,
    TextAreaType
  },
  mixins: [
    FormModalValidation
  ],
  props: {
    value: {type: Object, required: true},
    taskType: {type: String, required: true}
  },
  data () {
    return {
      // comes-in from parents injection
      clear: false
    }
  },
  created () {
    Validator.extend('money', {
      getMessage: field => 'Pole ' + field + ' musi być większe od 0 zł.',
      validate: (value) => {
        if (parseFloat(value.toString().replace(',', '.')) <= 0) {
          return false
        }
        return true
      }
    })
  },
  methods: {
    handleCorrectionChange (event) {
      if (event.target.checked === false) {
        this.$emit('invoiceClearCorrection')
      }
    }
  }
}
</script>
<style>
#invoiceForm .fv-datepicker > div > .btn-group.dropdown {
  display: block;
}

.correction-checkbox-first-label {
  display: flex;
  flex-direction: column-reverse;
  cursor: pointer;
}

.correction-checkbox-first-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
