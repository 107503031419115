<template>
  <div class="invoices">
    <h3 class="tab-header">
      Informacje o danych finansowych
    </h3>
    <section
      v-if="invoicesForDatatables.length > 0"
      class="content"
    >
      <grid
        :service="service"
        :content="preparedDataToList"
        :name="gridName"
        :columns="gridColumns"
        :searchable="false"
        :server-side="false"
      />
    </section>
    <p
      v-else
      class="content-empty"
    >
      Nie ma jeszcze danych finansowych
    </p>
    <invoice-edit-modal
      v-if="openInvoiceModal"
      :form-data="editedInvoice"
      :show="openInvoiceModal"
      :service="service"
      :task-number="taskId"
      :task-type="taskType"
      @close="function () {
        openInvoiceModal = false
        editedInvoice = {}
      }"
    />
  </div>
</template>

<script>
import $ from 'jquery'
import api from '../../../../api'
import Grid from '../grid/Grid'
import InvoiceEditModal from './modal/InvoiceEditModal'
import moment from 'moment'

export default {
  components: {
    InvoiceEditModal,
    Grid
  },
  props: {
    task: {type: Object},
    service: {
      type: String, default () {
        return ''
      }
    },
    taskId: {type: Number, required: true},
    taskType: {type: String, required: true},
    reloadInvoices: {type: Boolean, default: false}
  },
  data () {
    return {
      openInvoiceModal: false,
      editedInvoice: null,
      events: {
        loadInvoice: 'loadInvoice',
        datatablesLoaded: 'grid_task_invoices:dataTableLoaded'
      },
      headings: [
        'Numer faktury',
        'Data wystawienia',
        'Kwota',
        'Opis',
        'Rzeczoznawca',
        'Typ',
        'Korekta'
      ],
      appraisalSummary: 0,
      gridName: 'grid_task_invoices',
      gridColumns: [
        {
          className: 'details-control',
          searchable: false,
          orderable: false,
          render (data, type, row, meta) {
            return (
              `<div class="actions-wrapper">
                <input
                  type="checkbox"
                  id="${row.id}"
                  name="checkbox-invoice"
                  value="${row.id}"
                  class="invoices-input pull-left"
                ></input>
                <label for="${row.id}"></label>
                <i class="feather-chevron-right padded icon-invoices"></i>
              </div>`
            )
          }
        },
        {id: 1, data: 'task', title: 'Zlecenie', searchable: false},
        {id: 2, data: 'type', title: 'Rodzaj', searchable: false},
        {id: 3, data: 'invoiceNumber', title: 'Numer faktury', searchable: false},
        {
          id: 4,
          data: 'date',
          title: 'Data dodania',
          searchable: false
        },
        {id: 5, data: 'amount', title: 'Kwota netto', searchable: false},
        {
          id: 6,
          data: 'correction',
          title: 'Korekta',
          searchable: false,
          render (data, type, row, meta) {
            return data ? 'TAK' : 'NIE'
          }
        }
      ],
      invoicesForDatatables: []
    }
  },
  watch: {
    onReloadInvoice: function(val) {
      if(val) {
        this.loadInvoice()
      }
    }
  },
  computed: {
    preparedDataToList () {
      return this.invoicesForDatatables
    },
    onReloadInvoice: {
      get () {
        return this.reloadInvoices
      },
      set (value) {
        this.$emit('update:reloadInvoices', value)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadInvoice()
    })

    this.$events.on(this.events.loadInvoice, this.loadInvoice)
    this.$events.on(this.events.datatablesLoaded, this.expandRowListener)
  },
  beforeDestroy () {
    $(`#${this.gridName}`).off('click', 'i.icon-invoices', this.expandRow)
  },
  methods: {
    isAppraisal (invoice) {
      return invoice.title.includes('-')
    },
    isInAppraisal () {
      return this.task.number.includes('-')
    },
    prepareAppraisalSummary () {
      this.appraisalSummary = this.invoices.filter(invoice => this.isAppraisal(invoice))
        .map(invoice => invoice['summary']).map(summary => {
          return summary.value
        }).reduce((previousValue, currentValue) => {
          return previousValue + currentValue
        }, 0).toLocaleString('pl-PL', {style: 'currency', currency: 'PLN'})
    },
    loadInvoice () {
      this.invoicesForDatatables = []
      this.editedInvoice = {}
      this.onReloadInvoice = false

      let url = this.$isWithClients(this.service)
        ? `invoices?taskId=${this.task.id}` : `tasks/${this.task.id}/invoices`

      api.request(this.service, 'get', url)
        .then((response) => {
          this.assignDataforDatatables(response.data)
        })
        .catch((e) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować danych finansowych'
          })
        })
    },
    assignDataforDatatables (data) {
      this.invoicesForDatatables = []
      Object.entries(data).map(task => {
        return task[1].map(invoice => {
          invoice.task = task[0]
          invoice.date = invoice.date ? moment(invoice.date).format('YYYY-MM-DD') : ''
          invoice.type = invoice.hasOwnProperty('appraisalState') ? 'Koszt' : 'Przychód'
          this.invoicesForDatatables.push(invoice)
        })
      })
    },
    showEditInvoiceModal (invoice) {
      this.editedInvoice = invoice
      this.openInvoiceModal = true
    },
    expandRow (e) {
      let vm = this
      // let table = $(`#${vm.gridName}`)
      let tableComponent = vm.$children.filter(el => [...el.$el.classList].includes('table'))[0]
      let tr = $(e.currentTarget).closest('tr')
      let row = tableComponent.table.row(tr)
      let icon = $(e.currentTarget).closest('i.icon-invoices')
      if (row.child.isShown()) {
        // This row is already open - close it
        // row.off('click', `button#invoice-edit-${row.data().id}`, () => vm.showEditInvoiceModal(row.data()))
        row.child.hide()
        tr.removeClass('shown')
        icon.removeClass('down')
        icon.addClass('right')
      } else {
        // Open this row
        row.child(vm.format(row.data()), 'content').show()
        tr.addClass('shown')
        icon.removeClass('right')
        icon.addClass('down')
        // tr.closest('button.btn').addClass(`invoice-edit${row.data().id}`)
        // row.on('click', `td:not(.details-control)`, () => vm.showEditInvoiceModal(row.data()))
      }
    },
    expandRowListener () {
      let vm = this
      let tableComponent = vm.$children.filter(el => [...el.$el.classList].includes('table'))[0]

      let table = $(`#${this.gridName}`)
      table.on('click', 'i.icon-invoices', this.expandRow)
      table.on('change', 'input.invoices-input', this.onSelectInput)
      table.on('click', `td:not(.details-control, .content)`, (e) => {
        let tr = $(e.currentTarget).closest('tr')
        let row = tableComponent.table.row(tr)
        vm.showEditInvoiceModal(row.data())
      })
    },
    onSelectInput(e) {
      this.$emit('onUpdateSelectedInvoice', e.target.id)
    },
    format (d) {
      if (d.type === 'Koszt') {
        return '' +
          '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">Opis: </div>' + '<div class="history-values col-xs-7">' + `${d.description || 'b.d.'}` + '</div><div class="borderline col-xs-9"></div></div>' +
          '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">Powód opóźnienia: </div>' + '<div class="history-values col-xs-7">' + `${d.delay.map(delay => `${delay.label}`).join(', ') || 'b.d.'}` + '</div><div class="borderline col-xs-9"></div></div>' +
          ''
      }
      return '' +
        '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">Opis: </div>' + '<div class="history-values col-xs-7">' + `${d.description || 'b.d.'}` + '</div><div class="borderline col-xs-9"></div></div>' +
        '<div class="col-xs-12 bordered"><div class="col-xs-2 text-bold align-right">Oryginalny numer faktury: </div>' + '<div class="history-values col-xs-7">' + `${d.originalInvoiceNumber || 'b.d.'}` + '</div><div class="borderline col-xs-9"></div></div>' +
        ''
    }
  }
}
</script>
<style lang="scss" scooped>
.history-values {
  word-break: break-all;
  white-space: normal;
}

.padded {
  padding: 10px;
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.right {
  transition: all 0.5s;
}

.borderline {
  height: 1px;
  background-color: #42a5f6;
  margin-left: 2.333%;
}

.down {
  transform: rotate(90deg);
  transition: all 0.5s;
}

.btnD {
  height: 40px;
  width: 177px;
  padding: 0;
  border-radius: 2px;
  border: none;
  font-weight: 700;
  line-height: 18px;
}

.actions-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;

  & input + label {
    margin: 0 !important;
  }
}
</style>
