<script>
  import BaseFormModal from '../../../../share/modal/BaseFormModal'
  import FormModalMixin from '../../../../share/modal/FormModalMixin'
  import FormModal from './ZipPasswordForm'
  import api from '../../../../../api'
  import ValidatorReset from '../../../../share/mixins/ValidatorReset'
  import SelectType from '../../../../share/form/type/SelectType'
  import fileUtil from '../../../../../store/attachmentsState/utils/file'
  import errorReporter from '../../../../../store/attachmentsState/utils/errorReporter'

  export default {
    components: {
      SelectType,
      FormModal,
      BaseFormModal
    },
    mixins: [
      FormModalMixin,
      ValidatorReset
    ],
    props: {
      taskNumber: {required: true,  default: () => ''},
      service: { type: String, required: true, default: '' },
      fileUuids: {type: Array,required: true, default: () => []}
    },
    data () {
      return {
        model: {},
        title: 'Podaj hasło do pobieranego archiwum',
        okText: 'Pobierz',
      }
    },
    mounted () {
    },
    methods: {
      submitForm () {
        api.request('storage', 'post', `/download/${this.service}/search`, this.createDataToSend())
          .then((response) => {
            let file = response.data
            fileUtil.downloadBlob(fileUtil.base64toBlob(file.value, file.mimeType), this.fileUuids.length === 1 ? `${file.name}` : `${this.taskNumber}_załączniki.zip`)
            this.$emit('close')
            this.$emit('success')
          })
          .catch((error) => {
            errorReporter.reportError(error)
            this.$emit('close')
            this.$notify({
              type: 'error',
              title: 'Wystąpił błąd',
              text: 'Nie można pobrać pliku'
            })
          })
      },
      createDataToSend () {
        return {
          filesUuids: this.fileUuids,
          password: this.model.password
        }
      }
    }
  }
</script>
