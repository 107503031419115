var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12"},[_c('base-confirm-modal',{attrs:{"title":"","subject":_vm.selected,"show":_vm.deleteItemsModalVisible},on:{"close":function($event){_vm.deleteItemsModalVisible = false},"cancel":function($event){_vm.deleteItemsModalVisible = false},"confirm":_vm.startRemoveAttachments}},[_vm._v("Czy na pewno chcesz usunąć?")]),_c('div',[_c('div',[_c('div',{staticClass:"folder-imitation",style:({

            })},[_c('p',[_vm._v(_vm._s(_vm.$t('pages.taskDetails.attachments.all')))]),_vm._v(" "+_vm._s(' ')+" ")]),_c('div',{staticClass:"category-container-opened",style:({
              background: '#dce6f7',
              minHeight: '65vh',
              maxHeight: '65vh',
              cursor: 'auto'
            })},[_vm._l((_vm.dates),function(date){return [_c('div',{key:`separator-${date}`,staticClass:"attachments-date-separator"},[_c('div',{staticClass:"attachments-dates-item-select"},[_c('input',{attrs:{"id":date,"type":"checkbox","name":"selected"},domProps:{"checked":_vm.selectedDates.includes(date)},on:{"click":() => {
                        _vm.toggleDateItem(date)
                      }}}),_c('label',{attrs:{"for":date}},[_vm._v(_vm._s(date))])])]),_c('div',{key:date,staticClass:"attachments-preview-container"},_vm._l((_vm.filterAttachmentByDate(date)),function(singleAttachment){return _c('div',{key:singleAttachment.id,on:{"click":[function($event){if(!$event.ctrlKey)return null;return (() => {
                  _vm.lastSelectedAttachment = singleAttachment.id
                  _vm.toggleItem(singleAttachment.id)
                }).apply(null, arguments)},function($event){if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return (() => _vm.toggleItems(singleAttachment.id, _vm.filterAttachmentByDate(date))).apply(null, arguments)}]}},[_c('div',{staticClass:"attachments--item",on:{"mouseover":(e) => _vm.showTooltip(e, singleAttachment.id),"mouseleave":() => _vm.hideTooltip(singleAttachment.id)}},[_c('div',{staticClass:"attachments--item-select"},[_c('div',{on:{"click":() => _vm.toggleItem(singleAttachment.id)}},[_c('input',{attrs:{"id":singleAttachment.id,"type":"checkbox","name":"selected"},domProps:{"checked":_vm.selected.includes(singleAttachment.id)},on:{"click":() => {
                        _vm.lastSelectedAttachment = singleAttachment.id
                        _vm.toggleItem(singleAttachment.id)
                      }}}),_c('label',{attrs:{"for":singleAttachment.id}})]),_c('popover',{key:`popver-${singleAttachment.id}`,attrs:{"tag":'div',"auto-placement":true,"trigger":"hover"}},[_c('template',{slot:"popover"},[_c('div',{staticClass:"box box-component box-info popover--container"},[_c('p',{staticClass:"tooltip-name"},[_c('strong',[_vm._v("Nazwa:")]),_vm._v(" "+_vm._s(singleAttachment.filename)+" ")]),_c('p',[_c('strong',[_vm._v("Autor:")]),_vm._v(" "+_vm._s(singleAttachment.createdBy))])])]),_c('i',{staticClass:"feather-info attachments--info-icon"})],2)],1),_c('div',{staticClass:"attachment--item--container"},[_c('img',{attrs:{"id":singleAttachment.id,"src":singleAttachment.value},on:{"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return ((e) => _vm.$emit('preview', {e, singleAttachment})).apply(null, arguments)}}}),_c('div',{staticClass:"attachment--item-noWrap"},[_c('span',[_c('strong',[_vm._v("Nazwa: ")]),_c('p',{attrs:{"title":singleAttachment.filename}},[_vm._v(" "+_vm._s(_vm.fileName(singleAttachment))+" ")])]),_c('span',[_c('strong',[_vm._v("Dodano: ")]),_c('p',[_vm._v(_vm._s(singleAttachment.createdAt))])])])]),(singleAttachment.isSent)?_c('div',{staticClass:"attachment--item-send"},[_vm._m(0,true)]):_vm._e()])])}),0)]})],2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',[_vm._v("Wysłano do TU")]),_c('i',{staticClass:"feather-check-circle attachments--info-icon"})])
}]

export { render, staticRenderFns }