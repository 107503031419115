<template>
  <vue2-autocomplete
    :id="id"
    :url="source"
    :anchor="anchor"
    :label="label"
    :on-select="getData"
    :custom-params="{token: 'dev'}"
    :custom-headers="customHeaders"
    :required="required"
    :class-name="className"
    :classes="{ wrapper: 'form-wrapper', input: 'form-control', list: 'data-list', item: 'data-list-item' }"
    :placeholder="placeholder"
    :init-value="initialValue"
    :options="[]"
    :min="min"
    :debounce="debounce"
    :filter-by-anchor="true"
    :encode-params="true"
  />
</template>

<script>
import { abstractField } from 'vue-form-generator'
import Vue2Autocomplete from 'vue2-autocomplete-js'

export default {
  components: {
    Vue2Autocomplete
  },
  mixins: [
    abstractField
  ],
  data () {
    return {
      initialValue: ''
    }
  },
  computed: {
    source () { return this.schema.source },
    anchor () { return this.schema.anchor !== undefined ? this.schema.anchor : '' },
    label () { return this.schema.label !== undefined ? this.schema.label : '' },
    customHeaders () { return this.schema.customHeaders !== undefined ? this.schema.customHeaders : {} },
    required () { return this.schema.required ? this.schema.required : false },
    id () { return '' },
    className () { return '' },
    placeholder () { return '' },
    min () { return this.schema.min !== undefined ? this.schema.min : 3 },
    debounce () { return this.schema.debounce !== undefined ? this.schema.debounce : 2000 }
  },
  methods: {
    getData () {
    }
  }
}
</script>
